import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Impressum.scss';

const Impressum = () => {
  const navigate = useNavigate();

  return (
    <div className="impressum-container">
      <button className="close-button" onClick={() => navigate('/')}>
        X
      </button>
      <h2>Impressum</h2>
      <p>
        <strong>Timo Haseloff</strong><br />
        Breite Straße 7<br />
        39288 Burg <br />
        Sachsen-Anhalt, Deutschland
      </p>
      <p>
        <strong>E-Mail:</strong> <a href="mailto:timo@mtl-karriere.de">timo@mtl-karriere.de</a>
      </p>
    </div>
  );
};

export default Impressum;
