import React, { useState, useEffect } from 'react';
import './App.scss'; 
import Datenschutzerklärung from './Datenschutzerklärung';
import Impressum from './Impressum';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import 'ldrs/helix';
import 'ldrs/ring2';

const App = () => {
  const [darkMode, setDarkMode] = useState(true);
  const [selectedJob, setSelectedJob] = useState('');
  const [loading, setLoading] = useState(false);
  const [careerInfo, setCareerInfo] = useState('');

  // Function to toggle between Dark Mode and Light Mode
  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  // Handle changes in the input field for job selection
  const handleJobChange = (event) => {
    setSelectedJob(event.target.value);
  };

  // Handle the submission of the job selection to fetch career advice
  const handleSubmit = async () => {
    if (selectedJob.trim() === '') return; // Ensure there's a job entered
    setLoading(true);
    setCareerInfo('');
    await new Promise((resolve) => setTimeout(resolve, 2000)); // Simulate loading
    const response = await fetchGPTResponse(selectedJob);
    setCareerInfo(response);
    setLoading(false);
  };

  // Fetch response from GPT-3.5 based on selected job
  const fetchGPTResponse = async (selectedJob) => {
    const apiKey = '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';
    const url = 'https://api.openai.com/v1/chat/completions';

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiKey}`,
      },
      body: JSON.stringify({
        model: 'gpt-3.5-turbo',
        messages: [
          { role: 'system', content: 'Du bist ein Profi in der Karrierenberatung spezialisiert auf Bio und Medizin' },
          { role: 'user', content: `Ich bin zur Zeit ein ${selectedJob}, welche Karrieremöglichkeiten habe?` },
        ],
      }),
    });

    const data = await response.json();
    return data.choices[0].message.content;
  };

  // Handle key press, only trigger handleSubmit for Enter key when job input is focused
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();  // Prevent default behavior of the Enter key
      handleSubmit();          // Only trigger the submit action
    }
  };

  // Apply key press event listener to job input field
  useEffect(() => {
    const inputElement = document.querySelector('.modern-input');
    if (inputElement) {
      inputElement.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('keydown', handleKeyPress);
      }
    };
  }, [selectedJob]); // Ensure the event listener updates when the job changes

  return (
    <div className={darkMode ? 'app dark-mode' : 'app light-mode'} tabIndex="0">
      <Router>
        <header className="apple-header">
          <h1 className="apple-title">MTL-Karriere</h1>
        </header>

        <main className="main-content">
          <button onClick={toggleDarkMode} className="toggle-darkmode">
            {darkMode ? '☀️' : '🌙'}
          </button>

          <Routes>
            <Route
              exact
              path="/"
              element={
                <div className="container">
                  <p className="platform-description apple-style">
                    Unsere Plattform bietet Informationen und Karrierechancen für Medizinische Technologen für Laboratoriumsanalytik und Medizinisch-technische Laborassistenten. 
                    <br/> <br/>
                    Entdecke verschiedene Ausbildungsmöglichkeiten, Aufstiegschancen und potenzielle Führungspositionen – alles übersichtlich und interaktiv dargestellt. Nutze die KI-basierte Beratung, um deine nächsten Schritte in der beruflichen Laufbahn zu planen.
                  </p>

                  {!loading && (
                    <>
                      <div className="job-section">
                        <input
                          type="text"
                          placeholder="Gib deine Berufsbeschreibung ein"
                          onChange={handleJobChange}
                          className={`modern-input ${darkMode ? 'dark-mode' : 'light-mode'}`}
                        />
                        <button
                          onClick={handleSubmit}
                          className="submit-button"
                          id="submit-button"
                        >
                          Fortfahren
                        </button>
                        <p className="explanation-text apple-style-small">
                        <br />
                          1. Beruf angeben <br />
                          2. Karrierechancen entdecken
                        </p>
                      </div>
                    </>
                  )}

                  {loading && (
                    <div className="loading">
                      <l-ring-2
                        size="40"
                        stroke="5"
                        stroke-length="0.25"
                        bg-opacity="0.1"
                        speed="0.8"
                        color="green"
                      ></l-ring-2>
                      <p>Lädt...</p>
                    </div>
                  )}

                  {careerInfo && (
                    <div className="career-info">
                      <ReactMarkdown>{careerInfo}</ReactMarkdown>
                    </div>
                  )}
                </div>
              }
            />
            <Route path="/datenschutzerklaerung" element={<Datenschutzerklärung />} />
            <Route path="/impressum" element={<Impressum />} />
          </Routes>
        </main>

        <footer className="footer">
          <p>© 2024 MTL-Karriere.de</p>
          <p>
            <Link to="/datenschutzerklaerung">Datenschutzerklärung</Link> | <Link to="/impressum"> Impressum</Link>
          </p>
        </footer>
      </Router>
    </div>
  );
};

export default App;
